html,
body {
    height: 100%;
    overflow: hidden !important;
    overscroll-behavior: none !important;
    overscroll-behavior-y: none !important;
    overscroll-behavior-x: none !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
.css-textinput-11aywtz{
    width: 100%;
}